.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  float: right;
  display: flex;
  align-items: right;
  justify-content: right;
}

.contain {
  border-radius: 4px;
  border: 1px solid #848181;
}

.pagelabel {
  padding: 9px;
  font-size: small;
}

.btn:hover {
  background: #eee;
}

/* Zebra stripes */
tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr,
th,
td {
  border: 1px solid transparent;
}

input {
  padding-right: 4%;
}
