@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,600;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Damion&display=swap');
/* Font Families */

/* Font Sizes */

@media screen and (max-width: -1px) {
  .d-none-xs-down {
    display: none !important;
  }
}

.d-none-xs-up {
  display: none !important;
}

@media screen and (max-width: 319px) {
  .d-none-xs-down {
    display: none !important;
  }
}

@media screen and (min-width: 320px) {
  .d-none-xs-up {
    display: none !important;
  }
}

@media screen and (max-width: 575px) {
  .d-none-sm-down {
    display: none !important;
  }
}

@media screen and (min-width: 576px) {
  .d-none-sm-up {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .d-none-md-down {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .d-none-md-up {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .d-none-lg-down {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .d-none-lg-up {
    display: none !important;
  }
}

@media screen and (max-width: 1199px) {
  .d-none-xl-down {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .d-none-xl-up {
    display: none !important;
  }
}

@media screen and (max-width: 1599px) {
  .d-none-xxl-down {
    display: none !important;
  }
}

@media screen and (min-width: 1600px) {
  .d-none-xxl-up {
    display: none !important;
  }
}

.col-offset-0 {
  margin-left: 0;
}

.col-1 {
  flex-basis: 8.3333333333%;
}

.col-offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  flex-basis: 16.6666666667%;
}

.col-offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  flex-basis: 25%;
}

.col-offset-3 {
  margin-left: 25%;
}

.col-4 {
  flex-basis: 33.3333333333%;
}

.col-offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  flex-basis: 41.6666666667%;
}

.col-offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  flex-basis: 50%;
}

.col-offset-6 {
  margin-left: 50%;
}

.col-7 {
  flex-basis: 58.3333333333%;
}

.col-offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  flex-basis: 66.6666666667%;
}

.col-offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  flex-basis: 75%;
}

.col-offset-9 {
  margin-left: 75%;
}

.col-10 {
  flex-basis: 83.3333333333%;
}

.col-offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  flex-basis: 91.6666666667%;
}

.col-offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  flex-basis: 100%;
}

.col-offset-12 {
  margin-left: 100%;
}

@media screen and (min-width: 320px) {
  .col-xs-offset-0 {
    margin-left: 0;
  }
  .col-xs-1 {
    flex-basis: 8.3333333333%;
  }
  .col-xs-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xs-2 {
    flex-basis: 16.6666666667%;
  }
  .col-xs-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xs-3 {
    flex-basis: 25%;
  }
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  .col-xs-4 {
    flex-basis: 33.3333333333%;
  }
  .col-xs-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xs-5 {
    flex-basis: 41.6666666667%;
  }
  .col-xs-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xs-6 {
    flex-basis: 50%;
  }
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  .col-xs-7 {
    flex-basis: 58.3333333333%;
  }
  .col-xs-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xs-8 {
    flex-basis: 66.6666666667%;
  }
  .col-xs-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xs-9 {
    flex-basis: 75%;
  }
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  .col-xs-10 {
    flex-basis: 83.3333333333%;
  }
  .col-xs-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xs-11 {
    flex-basis: 91.6666666667%;
  }
  .col-xs-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-xs-12 {
    flex-basis: 100%;
  }
  .col-xs-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 576px) {
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-1 {
    flex-basis: 8.3333333333%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    flex-basis: 16.6666666667%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    flex-basis: 25%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    flex-basis: 33.3333333333%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    flex-basis: 41.6666666667%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    flex-basis: 50%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    flex-basis: 58.3333333333%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    flex-basis: 66.6666666667%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    flex-basis: 75%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    flex-basis: 83.3333333333%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    flex-basis: 91.6666666667%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    flex-basis: 100%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 768px) {
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-1 {
    flex-basis: 8.3333333333%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    flex-basis: 16.6666666667%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    flex-basis: 25%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    flex-basis: 33.3333333333%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    flex-basis: 41.6666666667%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    flex-basis: 50%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    flex-basis: 58.3333333333%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    flex-basis: 66.6666666667%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    flex-basis: 75%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    flex-basis: 83.3333333333%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    flex-basis: 91.6666666667%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    flex-basis: 100%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 992px) {
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-1 {
    flex-basis: 8.3333333333%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    flex-basis: 16.6666666667%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    flex-basis: 25%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    flex-basis: 33.3333333333%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    flex-basis: 41.6666666667%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    flex-basis: 50%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    flex-basis: 58.3333333333%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    flex-basis: 66.6666666667%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    flex-basis: 75%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    flex-basis: 83.3333333333%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    flex-basis: 91.6666666667%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    flex-basis: 100%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .col-xl-1 {
    flex-basis: 8.3333333333%;
  }
  .col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-2 {
    flex-basis: 16.6666666667%;
  }
  .col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-3 {
    flex-basis: 25%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    flex-basis: 33.3333333333%;
  }
  .col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-5 {
    flex-basis: 41.6666666667%;
  }
  .col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-6 {
    flex-basis: 50%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    flex-basis: 58.3333333333%;
  }
  .col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-8 {
    flex-basis: 66.6666666667%;
  }
  .col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-9 {
    flex-basis: 75%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    flex-basis: 83.3333333333%;
  }
  .col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-11 {
    flex-basis: 91.6666666667%;
  }
  .col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-12 {
    flex-basis: 100%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1600px) {
  .col-xxl-offset-0 {
    margin-left: 0;
  }
  .col-xxl-1 {
    flex-basis: 8.3333333333%;
  }
  .col-xxl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xxl-2 {
    flex-basis: 16.6666666667%;
  }
  .col-xxl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xxl-3 {
    flex-basis: 25%;
  }
  .col-xxl-offset-3 {
    margin-left: 25%;
  }
  .col-xxl-4 {
    flex-basis: 33.3333333333%;
  }
  .col-xxl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xxl-5 {
    flex-basis: 41.6666666667%;
  }
  .col-xxl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xxl-6 {
    flex-basis: 50%;
  }
  .col-xxl-offset-6 {
    margin-left: 50%;
  }
  .col-xxl-7 {
    flex-basis: 58.3333333333%;
  }
  .col-xxl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xxl-8 {
    flex-basis: 66.6666666667%;
  }
  .col-xxl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xxl-9 {
    flex-basis: 75%;
  }
  .col-xxl-offset-9 {
    margin-left: 75%;
  }
  .col-xxl-10 {
    flex-basis: 83.3333333333%;
  }
  .col-xxl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xxl-11 {
    flex-basis: 91.6666666667%;
  }
  .col-xxl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-xxl-12 {
    flex-basis: 100%;
  }
  .col-xxl-offset-12 {
    margin-left: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.row.no-margin {
  margin: 0 !important;
}

.container {
  margin: 0 auto;
  max-width: 768px;
}

.container-fluid {
  margin: 0;
  max-width: 100%;
}

* {
  outline: 0;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1em;
}
