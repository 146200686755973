.rotate-center {
  -webkit-animation: rotate-center 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    infinite both;
  animation: rotate-center 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite
    both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
